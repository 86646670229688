import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, HostBinding, OnDestroy } from '@angular/core';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { ValidationService } from '@bs/forms/services/validation.service';
import { IFooter } from '@bs/models/cms/footer';
import { AppSettings } from '@bs/models/common/app-settings';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { CmsService } from '@bs/services/services/core/cms.service';
import { WindowService } from '@bs/universal/window.service';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LicenseComponent } from '../license/license.component';
import { MenuComponent } from '../menu/menu.component';
import { ToTopComponent } from '../to-top/to-top.component';

@Component({
  selector: 'footer',
  templateUrl: 'footer.component.html',
  standalone: true,
  imports: [NgClass, ToTopComponent, NgTemplateOutlet, MenuComponent, TranslateModule, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, LicenseComponent, SvgIconDirective]
})
export class FooterComponent implements OnDestroy {

  @HostBinding('class')
  layoutRender = this.config.layout?.footer;

  footer: IFooter;

  isMobile: boolean;
  settings: AppSettings;
  vrs: string;
  minAge: number;

  payments: Array<{name: string}>;

  subs = new Subscription();

  constructor(private config: EnvironmentConfig, private cmsService: CmsService, windowService: WindowService, appSettingsService: AppSettingsService, validationService: ValidationService) {
    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({ settings, valueChanged }) => {
        this.settings = settings;
        if (['languageCode'].includes(valueChanged)) {
          this.loadData();
        }
      }
    }));
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
    this.vrs = this.config.appVersion;
    this.minAge = validationService.minAge;
    this.loadData();

    this.payments = config.payments
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadData() {
    this.subs.add(this.cmsService.getFooter().subscribe({
      next: footer => this.footer = footer
    }));
  }
}
