<ng-template #linkTpl let-item>
  <a [routerLink]="'/'+ lang + item.routerLink" class="nav-link d-flex align-items-center" [queryParams]="item.queryParams|keys:'='" routerLinkActive="active" [fragment]="item.fragment">
    @if (item.icon) {
      <svg [icon]="item.icon | lowercase | sluggify" lib="global" [size]="{w:18, h:18}" [class]="item.icon | sluggify" class="me-2"></svg>
    }
    <span class="flex-grow-1 item-label">{{ item.label }}</span>
    @if (item.badge) {
      <span class="badge badge-new">{{ item.badge }}</span>
    }
  </a>
</ng-template>

<ng-template #externalTpl let-item>
  <a [href]="item.url" class="nav-link d-flex" target="_blank">
    @if (item.icon) {
      <svg [icon]="item.icon | lowercase | sluggify" lib="global" [size]="{w:18, h:18}" [class]="item.icon | sluggify" class="me-2"></svg>
    }
    <span class="flex-grow-1" [ngClass]="headerLayout === 'header3' && isDesktop ? 'icomoon-' + (item.label | lowercase | sluggify) : ''">{{ item.label }}</span>
  </a>
</ng-template>

<ng-template #noLinkTpl let-item>
  <button (click)="item.expanded = !item.expanded" [class.active]="item.expanded" [routerLink]="item.url" class="p-link" routerLinkActive="active" type="button">
    @if (item.icon) {
    }
    <span>{{ item.label }}</span>
    @if (item.badge) {
      <span class="badge">{{ item.badge }}</span>
    }
  </button>
</ng-template>

<ng-template #itemTpl let-item>
  <div (click)="item.expanded = !item.expanded" [ngClass]="{'has-sub': item.items?.length}" class="w-100">
    <ng-template [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="item.routerLink ? linkTpl: item.url ? externalTpl:noLinkTpl"></ng-template>
  </div>
</ng-template>

<ng-template #hasSubTpl let-item let-level="level">
  <ng-template [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="itemTpl"></ng-template>
  <ul [class.column]="vertical" class="navbar lev-{{level}}">
    <ng-template [ngTemplateOutletContext]="{ $implicit: item.items, level:(level+1) }" [ngTemplateOutlet]="recursiveList"></ng-template>
  </ul>
</ng-template>

<ng-template #recursiveList let-items let-level="level">
  @for (item of items; track item) {
    <li [ngClass]="{leaf: !item.items, expanded: item?.expanded || isLinkActive(item)}" class="nav-item">
      <ng-template [ngTemplateOutletContext]="{ $implicit: item, level:level }" [ngTemplateOutlet]="item.items?.length ? hasSubTpl:itemTpl"></ng-template>
    </li>
  }
</ng-template>

@if (menu?.items) {
  <ul [class.column]="vertical">
    <ng-template [ngTemplateOutletContext]="{ $implicit: menu.items, level:1 }" [ngTemplateOutlet]="recursiveList"></ng-template>
  </ul>
}
