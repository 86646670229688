<ng-template #licenseTpl let-license>
  <div [id]="'apg-'+license.sealId" [attr.apgSealId]="license.sealId" data-apg-image-size="48" data-apg-image-type="basic-small"></div>
</ng-template>

<ng-template #imageTpl>
  @if (currentLicense?.image) {
    <img [alt]="currentLicense?.alt" [src]="'./assets/images/licenses/' + currentLicense?.image" class="license-img"/>
  }
</ng-template>

<ng-template #currentLicenseTpl>
  <div>
    @if (currentLicense?.href) {
      <a [href]="currentLicense.href" target="_blank">
        <ng-template [ngTemplateOutlet]="imageTpl"></ng-template>
      </a>
    } @else {
      @if (currentLicense?.image) {
        <img [alt]="currentLicense?.alt" [src]="'./assets/images/licenses/' + currentLicense?.image" class="license-img"/>
      }
    }
  </div>
</ng-template>

<ng-template #gamingCuracaoIframe let-license>
  <iframe [src]="'https://licensing.gaming-curacao.com/validator/?lh='+license.sealId+'&template=tseal' | trusted:'RESOURCE_URL'" width="150" height="50" style="border:none;"></iframe>
</ng-template>

<ng-template #gamingCuracaoCeg let-license>
  <a [href]="license.sealId" target="_blank">
    <img [src]="license.link" alt="Curacao EGaming" width="75"/>
  </a>
</ng-template>

<ng-template #gamingCuracaoCGB let-license>
  <a [href]="license.sealId" target="_blank">
    <img [src]="license.link" alt="Curacao CGB" width="75"/>
  </a>
</ng-template>

<div class="col-12 license-area d-flex justify-content-center align-items-center">
  <!--  <ng-container [ngTemplateOutlet]="license.typeId === 1 ? licenseTpl:currentLicenseTpl" [ngTemplateOutletContext]="{$implicit:license}"></ng-container>-->
  @switch (license.typeId) {
    @case (1) {
      <ng-template [ngTemplateOutlet]="licenseTpl" [ngTemplateOutletContext]="{$implicit:license}"></ng-template>
    }
    @case (13) {
      <ng-template [ngTemplateOutlet]="gamingCuracaoIframe" [ngTemplateOutletContext]="{$implicit:license}"></ng-template>
    }
    @case (17) {
      <ng-template [ngTemplateOutlet]="gamingCuracaoCeg" [ngTemplateOutletContext]="{$implicit:license}"></ng-template>
    }
    @case (19) {
      <ng-template [ngTemplateOutlet]="gamingCuracaoCGB" [ngTemplateOutletContext]="{$implicit:license}"></ng-template>
    }
    @default {
      <ng-template [ngTemplateOutlet]="currentLicenseTpl" [ngTemplateOutletContext]="{$implicit:license}"></ng-template>
    }
  }
</div>
<div class="col-12 license-area d-flex justify-content-center align-items-center">
  <span>{{ license.text }}</span>
</div>
<div class="col-12 license-area d-flex justify-content-center align-items-center">
  <span>{{ license.text2 }}</span>
</div>
