import { LowerCasePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { SluggifyPipe } from '@bs/common/pipes/sluggify.pipe';
import { IMenuItem } from '@bs/models/cms/menu';
import { Webapps } from '@bs/models/cms/webapps';
import { EnvironmentConfig } from '@bs/models/common/environment-config';

import { WindowService } from '@bs/universal/window.service';

import { TranslateService } from '@ngx-translate/core';
import { KeysPipe } from '../../pipes/keys.pipe';

@Component({
  selector: '[menu]',
  templateUrl: './menu.component.html',
  styleUrls: ['menu.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, NgClass, NgTemplateOutlet, LowerCasePipe, KeysPipe, SluggifyPipe, SvgIconDirective]
})
export class MenuComponent {

  @Input()
  menu: any;

  @Input()
  vertical: boolean;

  lang: string;

  headerLayout = this.config.features.headerLayout || 'header1';
  isDesktop = false;

  constructor(translate: TranslateService, private router: Router, private config: EnvironmentConfig,
              windowService: WindowService) {
    this.lang = translate.currentLang;
    translate.onLangChange.subscribe({
      next: data => this.lang = data.lang
    });
    windowService.device$.subscribe({
      next: device => this.isDesktop = device.isDesktop
    });
    // console.log('menu', this.menu);
  }

  public isLinkActive(menuItem: IMenuItem): boolean {
    if (menuItem.routerLink) {
      return this.router.url.includes(menuItem.routerLink);
    }
    return false;
    /*else {
      if (menuItem.items && menuItem.items.length) { // if there are subs dig-in recursively
        const ml = menuItem.items as Array<IMenuItem>;

        return ml.some(mi => this.isLinkActive(mi));
      } else {
        return false;
      }
    }*/
  }

  getIconClass(icon: string): string {
    if (this.headerLayout === 'header3' && this.isDesktop || this.config.webAppId === Webapps.maggie) {
      return `icomoon-${icon}`;
    }
    return '';
  }
}
