import { Component } from '@angular/core';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';

@Component({
  selector: 'to-top',
  templateUrl: './to-top.component.html',
  styles: [`:host {
    display: block
  }`],
  standalone: true,
  imports: [
    SvgIconDirective
  ]
})
export class ToTopComponent {

  constructor(private settingsService: AppSettingsService) {
  }

  toTop() {
    this.settingsService.scrollTo$.next('top');
  }
}
